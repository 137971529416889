<template>
  <div class="shopmall">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="积分商城" left-arrow @click-left="toBack" :border="false" />
    <!-- 积分 -->
    <div class="jifen">
      <h3>
        可用积分
        <span>{{ky_score}}</span>
      </h3>
      <div class="jf_info">
        <div class="ji" @click="todetailed">
          <img src="../../../public/statics/images/my/ji1.png" alt />
          <span>积分明细</span>
        </div>
        <div class="ji" @click="toDui">
          <img src="../../../public/statics/images/my/ji2.png" alt />
          <span>兑换记录</span>
        </div>
        <div class="ji" @click="toPath('/score', {})">
          <img src="../../../public/statics/images/my/ji3.png" alt />
          <span>积分规则</span>
        </div>
      </div>
    </div>
    <!-- 积分商品 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="jf_list">
        <van-list
          v-model:loading="loading"
          class="article1"
          :finished="finished"
          :finished-text="goodsList.length == 0 ? '' : '没有更多了'"
          @load="getGoodsList"
        >
          <div
            class="jf_item"
            v-for="(item,index) in goodsList"
            :key="index"
            @click="toGoodinfo(item.id)"
          >
            <!-- <img src="../../../public/statics/images/task/detail_1.jpg" alt /> -->
            <img :src="adddom(item.thumb)" alt />
            <div class="bottomcon">
              <div class="title">
                <span>{{item.title}}</span>
              </div>
              <div class="score">
                <span class="fen">{{item.score > 10000 ? (item.score / 10000).toFixed(1) + '万' : item.score}}积分</span>
                <span class="dui">兑换</span>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-if="!loading && goodsList.length == 0" description="暂无数据" />
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      goodsList: [], //积分商品列表
      pages: {
        page: 1,
        size: 10
      },
      ky_score: null, //可用积分
      refreshing: false, //下拉刷新
      loading: false,
      finished: false
    };
  },
  created() {
    this.ky_score = Cookies.get("kyscore");
    // this.getGoodsList();
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.goodsList = [];
      that.loading = true;
      that.finished = false;
      that.pages.page = 1;
      that.getGoodsList();
    },
    getGoodsList() {
      // console.log("111");
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        page: that.pages.page,
        size: that.pages.size
      };
      that.$api.integral.list(params).then(res => {
        if (res.code == 200) {
          that.goodsList = that.goodsList.concat(res.result);
          that.pages.page++;
          // that.goodsList = res.result;
          // 加载状态结束
          that.loading = false;
          if (res.result.length < 1) {
            that.finished = true;
          }
        } else {
          that.loading = false;
          // that.finished = true;
          that.$toast(res.msg);
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    // 跳转到积分明细
    todetailed() {
      this.$router.push({
        path: "/detailed"
      });
    },
    // 跳转到积分商品详情
    toGoodinfo(id) {
      this.$router.push({
        path: "/good_info",
        query: {
          id: id
        }
      });
    },
    // 跳转到兑换记录
    toDui() {
      this.$router.push({
        path: "/dui_record"
      });
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  }
};
</script>

<style>
</style>